<template>
  <el-switch @change="change" v-model="val"></el-switch>
</template>

<script>
export default {
  props: {
    fieldName: {
      required: true
    },
    value: {
      required: true
    },
    customData: {
      required: false,
      default: {}
    },
    saveAction: {
      required: false,
      default: {}
    }
  },
  data() {
    return {
      val: false
    }
  },
  beforeMount() {
    this.val = (this.value == 1)
  },
  methods: {
    change(val) {
      let oldStatus = (val == 1)? 0 : 1
      this.saveAction(this.customData, (val)? 1 : 0, (updateStatus) => {
        if (updateStatus) {

        } else {
          this.val = oldStatus
        }

      }, this.fieldName)
    }
  }
}
</script>
