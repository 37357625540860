import board from './utility/Board';
import text from './text/Text';
import pagination from '@/components/pagination/View.vue';
import moduleListLayout from '@/modules-config/layouts/list/View.vue';
import popover from "@/components/popover/View";
import smartInput from "devegram-vue-smart-input/src/View";
import vDropdown from '@/components/button-dropdown/View';
import vSwitch from '@/components/switch/View';
import fullCalender from '@/components/full-calender/View'
import fullCalenderDialog from '@/components/full-calender/dialog-view/View'
import selectEventInput from '@/components/full-calender/select-event-input/View'

$vue.component('app-board', board);
$vue.component('vSwitch', vSwitch);
$vue.component('pagination', pagination);
$vue.component('moduleListLayout', moduleListLayout);
$vue.component('popover', popover);
$vue.component('vDropdown', vDropdown);
$vue.component('smartInput', smartInput);
$vue.component('t-text', text);
$vue.component('fullCalender', fullCalender);
$vue.component('fullCalenderDialog', fullCalenderDialog);
$vue.component('selectEventInput', selectEventInput);
