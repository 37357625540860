<template>
  <div v-if="amReady">
    <!--<div v-if="createEventMode" class="border-bottom mb-3 pb-2">
      <i class="el-icon-edit-outline"></i><small>Select a date to create new event</small>
    </div>-->

    <FullCalendar @eventRender="eventRender" :options="calendarOptions"/>

    <el-dialog :visible.sync="newEventDialog" append-to-body custom-class="el-custom-dialog" title="" top="5vh"
               width="75%">
      <createNewEvent :dataToBind="dataToBind" :moduleDeleteAction="onEventDelete" :onCreateCallback="eventCreatedFromDialog" :onEditCallback="eventCreatedFromDialog" :editMode="eventEditMode" :selectedDateInfo="selectedDateInfo" v-if="newEventDialog"></createNewEvent>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px" append-to-body>
      <div align="center" class="mt-2 mb-2">You want to remove this event ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="selectEventModeDialog" center title="Attention" width="420px" append-to-body>
      <div align="center" class="mt-2 mb-2">You want to select this event ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="selectEventModeDialog = false" size="small">Cancel</el-button>
        <el-button @click="eventHasBeenSelected" size="small" type="success">Yes</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// https://fullcalendar.io/docs/vue
// https://codesandbox.io/s/github/fullcalendar/fullcalendar-example-projects/tree/master/vue?file=/src/DemoApp.vue:239-252
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import rrulePlugin from '@fullcalendar/rrule'

import Event from "./event/View"
const EventClass = $vue.extend(Event)

export default {
  props: {
    createEventMode: {
      required: false
    },
    selectEventMode: {
      required: false,
      default: false
    },
    onEventSelected: {
      required: false
    }
  },
  beforeMount() {this.getFormData()},
  components: {
    createNewEvent: () => import ('./create-event/View.vue'),
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      eventsSelfCounter: {},
      amReady: false,
      dataToBind: false,
      selectEventModeDialog: false,
      eventEditMode: false,
      deleteDialogVisible: false,
      newEventDialog: false,
      selectedDateInfo: {},
      eventsObj: {},
      targetEvent: false,
      calendarOptions: {

        eventDidMount: this.eventRender,
        events: [],
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          rrulePlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventAdd: this.eventAdd,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
    }
  },
  methods: {
    eventHasBeenSelected () {
      $store.dispatch('Time-Event-api/setSelectedEvent', this.targetEvent).then( r => {
        this.selectEventModeDialog = false
        this.onEventSelected(this.targetEvent)
      })
    },
    removeActionApi () {
      let event = this.targetEvent
      this.deleteDialogVisible = false
      this.removeEvent({id: event.extendedProps.model.id}).then(response => {
        this.newEventDialog = false
        event.remove()
        //this.listCalenderEventsMethod()
        $services['notify']({
          type: 'success',
          title: this.$ml.get('deleted'),
        });
      }).catch(error => {
        if (error.response.status == 400) {
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"
          }
        }
      })
    },
    onEventEdit (params) {
      let event = params.event
      let info  = params.info

      this.selectedDateInfo = event
      this.dataToBind = event.extendedProps.model;
      this.eventEditMode = true
      this.newEventDialog = true
    },
    onEventDelete (params = false) {
      let event = null
      if (params) {
        event = params.event
      } else {
        event = this.selectedDateInfo
      }

      this.targetEvent = event
      this.deleteDialogVisible = true
    },
    ...$mapActions('Time-Event-api', {
      listCalenderEvents: 'listCalenderEvents',
      removeEvent: 'remove',
    }),
    listCalenderEventsMethod () {
      return this.listCalenderEvents().then( r => {
        this.calendarOptions.events = r
        console.log('r', r)
      })
    },
    getFormData() {
      let promises = []
      promises.push(this.listCalenderEventsMethod())
      Promise.all(promises).then((values) => {
        this.amReady = true
      })
    },
    eventRender (info) {
      let sc = this.eventsSelfCounter
      const event = new EventClass({
        propsData: {
          event: info.event,
          info: info,
        }
      })


      let eventId = info.event._def.extendedProps.model.id
      if (sc.hasOwnProperty(eventId)) {
        sc[eventId] += 1
      } else {
        sc[eventId] = 1
      }
      event.selfRepeated = sc[eventId];

      event.$on('edit', this.onEventEdit)
      event.$on('delete', this.onEventDelete)
      event.$mount();

      this.eventsObj[event._uid] = event;
      info.el.setAttribute('data-vue-id', event._uid);
      info.el.innerHTML = ""
      info.el.appendChild(event.$el)

      event.$el.parentNode.style.background = 'none'
      event.$el.parentNode.style.border = 'none'
    },
    eventCreatedFromDialog (event) {
      // this.selectInfo.view.calendar.addEvent(event)
      this.newEventDialog = false
      this.listCalenderEventsMethod()
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    eventAdd(event) {},
    handleDateSelect(selectInfo) {
      if (this.createEventMode) {
        this.eventEditMode = false;
        this.selectedDateInfo = selectInfo
        this.newEventDialog = true
        this.dataToBind = false
      }
      /*let a = new Date(selectInfo.start)
      console.log('selectInfo', a.getHours())*/

      /*selectInfo.view.calendar.addEvent({
        // standard property
        title: 'my recurring event',
        rrule: {
          freq: 'weekly',
          interval: 1,
          byweekday: [ 'mo', 'fr' ],
          dtstart: '2021-08-01T10:30:00',
          until: '2021-09-01'
        },
        start: '2021-08-05T10:30:00',
        end: '2021-08-05T11:30:00',
        description: 'Lecture',
        duration: '12:00'
      })*/

      /*
      console.log('startStr', selectInfo.startStr)
      console.log('endStr', selectInfo.endStr)*/
      //this.selectInfo = selectInfo
      /*let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: this.createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }*/
    },
    handleEventClick(info) {
      if (this.selectEventMode) {
        this.selectEventModeDialog = true
        this.targetEvent = info.event
      }
    },
    handleEvents(events) {
      this.eventsSelfCounter = {}
      this.currentEvents = events
    },
  }
}
</script>
