<template>
  <el-popover
      :offset="offset"
      :title="title"
      :placement="placement"
      trigger="hover"
      :open-delay="openDelay"
      :width="width">
    <div slot="reference">
      {{data}}<slot name="body"></slot>
    </div>
    <div v-html="content"></div>
  </el-popover>
</template>

<script>
export default {
  props: {
    openDelay: {
      default: 250
    },
    offset: {
      default: 100
    },
    placement: {
      default: 'top-start'
    },
    width: {
      default: '240'
    },
    title: {
      default: 'info'
    },
    data: {
      required: true
    },
    content: {
      required: true
    }
  }
}
</script>
