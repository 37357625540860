<template>
  <div>
    <div class="mb-2"><span v-if="rules" :class="'field-mark-'+rules"></span> {{ label }}</div>
    <fullCalenderDialog :visible.sync="showDialog" :selectEventMode="true" :createEventMode="createEventMode" :onEventSelected="eventSelected"></fullCalenderDialog>
    <div class="border">
      <el-button type="info" size="mini" @click="showDialog = true"> Select</el-button>
      <span style="display: inline-block; margin-left: 10px;" v-html="eventName"></span>
    </div>
    <div class="error-field-validate-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  props: {
    createEventMode: {
      required: false,
      default: false
    },
    label: {
      required: false,
      default: 'Select Event'
    },
    eventName: {
      required: false,
      default: '<i> no event is selected </i>'
    },
    rules: {
      required: false,
      default: ''
    }
  },

  data () {
    return {
      showDialog: false,
      selectedEvent: null,
      errorMessage: null
    }
  },

  methods: {
    validate() {
      if (this.selectedEvent || !this.rules.includes('required')) {
        this.errorMessage = null
        return true
      }

      this.errorMessage = 'please select an event'
      return false
    },
    eventSelected (event) {
      this.showDialog = false
      this.$emit('update:eventName', event._def.extendedProps.model.name)
      this.selectedEvent = event
      this.errorMessage = null
      this.$emit('changed', {'event' :event, 'model' : event._def.extendedProps.model})
    }
  }
}
</script>
