<template>
  <el-dropdown @command="actionsHandler($event, params)" trigger="click">
    <el-button icon="el-icon-more" size="mini" type="plain"></el-button>
    <el-dropdown-menu slot="dropdown">
      <span v-for="(item, key) in items" :key="key">
        <el-dropdown-item :command="item.action" v-if="isOptionValid(item)" :class="{'border-bottom mb-2': item.borderBottom}">
          <i :class="item.icon"></i> {{ item.label }}
        </el-dropdown-item>
      </span>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  methods: {
    isOptionValid(o) {
      if (!o.hasOwnProperty('notForProfiles')) return true
      if (o.hasOwnProperty('notForProfiles') && !o.notForProfiles.includes(this.userProfileId)) return true
      return false
    }
  },
  props: {
    userProfileId: {
      required: true
    },
    actionsHandler: {
      required: true
    },
    items: {
      required: true
    },
    params: {
      required: false,
      default: {}
    }
  }
}
</script>

<style scoped>
.el-dropdown-menu__item {
  padding: 0 12px !important;
}
</style>
