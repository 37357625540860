<template>
  <el-dialog :visible="visible" class="dialog-no-header" :show-close="false" @close="$emit('update:visible', false)" append-to-body custom-class="el-custom-dialog" title="" top="1vh" width="90%">
    <div v-if="createEventMode" class="bg-white" style="width: 60px; padding: 2px 5px;position: fixed; left: 2px; top:1.5vh; font-size: 11px;">
      <i class="el-icon-plus"></i> New
    </div>

    <div v-if="selectEventMode" class="bg-white" style="width: 60px; padding: 2px 5px;position: fixed; left: 2px; top:4.5vh;font-size: 11px;">
      <i class="el-icon-check"></i> Select
    </div>

    <fullCalender :createEventMode="createEventMode" :selectEventMode="selectEventMode" :onEventSelected="onEventSelected"></fullCalender>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      required: true,
      default: false
    },
    createEventMode: {
      default: false
    },
    selectEventMode: {
      default: false
    },
    onEventSelected: {
      default: () => {}
    }
  }
}
</script>
