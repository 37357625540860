<template>
  <div class="w-100 p-1" :class="getContainerClass()" style="background-color:#388aa0;">
    <popover :title="event.title" :content="contentGenerator()" data="" offset="20">
      <template v-slot:body>
        <i :class="getEventIcon()"></i> <span v-if="hasRecurring()"> {{selfRepeated}} </span>
        <i @click="$event.stopPropagation();editHandler('edit')" class="d-inline-block el-icon-edit" style="font-size:12px; margin-left: 5px; margin-right: 5px;:margin-top: 2px;"></i>
         {{event.title}}
        <!--<i @click="deleteHandler('delete')" class="float-right d-inline-block el-icon-delete" style="margin-right: 5px"></i>-->
      </template>
    </popover>
  </div>
</template>
<script>
export default {
  name: 'Event',
  props: ['event', 'info'],
  data () {
    return {
      selfRepeated: 0
    }
  },
  methods: {
    hasRecurring () {
      return (this.event._def.recurringDef)? true : false
    },
    contentGenerator () {
      let props = this.event._def.extendedProps
      let d =  props.event_type_name
      d += "<br> <i class='el-icon-time'></i> " + props.model.start_time + " - " + props.model.end_time
      d += "<br> <i class='" + this.getEventIcon() +"'></i> " + this.getStartDate() + ' - ' + this.getEndDate()
      d += "<br> <i class='el-icon-location'></i> " + props.place
      return d
    },
    getEventIcon () {
      if (this.event._def.recurringDef) return 'el-icon-refresh-left'
      return 'el-icon-date'
    },
    getStartDate () {
      //if (this.event.endStr) return this.event.endStr
      return this.event._def.extendedProps.model.date_start
    },
    getEndDate () {
      //if (this.event.endStr) return this.event.endStr
      return this.event._def.extendedProps.model.date_end
    },
    editHandler () {
      this.$emit('edit', {event: this.event, info: this.info})
    },
    deleteHandler () {
      this.$emit('delete', {event: this.event, info: this.info})
    },
    getContainerClass () {
      /*if (this.info.view.type == 'dayGridMonth') return 'text-white';
      if (this.info.view.type == 'timeGridWeek') return 'text-white';*/
      return 'text-white'
    }
  }
}
</script>
